import { Trans } from "@lingui/macro";
import React, { FC, useState } from "react";
import { useAuth } from "@helpers/hooks/useAuth";
import { PayoutMethod, SupplierPayoutMethodStatus } from "@graphql/types";
import { useRouter } from "next/router";
import { ButtonLink } from "@components/ui/Button";
import Modal from "@components/ui/Modal";

const PayoutMissingNotification: FC = () => {
  const { auth } = useAuth();
  const router = useRouter();

  const [showModal, setShowModal] = useState(() => {
    if (!auth || auth.__typename !== "Supplier") {
      return false;
    }

    if (router.route === "/auth/account/payments") {
      return false;
    }

    if (auth.payoutMethodStatus === SupplierPayoutMethodStatus.Completed) {
      return false;
    }

    if (auth?.stats.bookingsCount === 0) {
      return false;
    }
    return true;
  });

  if (!auth || auth.__typename !== "Supplier") {
    return null;
  }

  if (router.route === "/auth/account/payments") {
    return null;
  }

  if (auth.payoutMethodStatus === SupplierPayoutMethodStatus.Completed) {
    return null;
  }

  if (auth?.stats.bookingsCount === 0) {
    return null;
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      title="Warning"
      onClose={() => {
        setShowModal(false);
      }}
    >
      <div className="flex flex-col justify-around items-center w-full px-5">
        {auth.payoutMethodType === PayoutMethod.Bank && (
          <Trans id="auth.supplier.payout.bank.missing">
            Please complete your Bank information to receive your future
            payments.
          </Trans>
        )}
        {auth.payoutMethodType === PayoutMethod.Paypal && (
          <Trans id="auth.supplier.payout.paypal.missing">
            Please connect your PayPal account to receive your future payments.
          </Trans>
        )}

        <div className="mt-4 cursor-pointer">
          <ButtonLink
            rounded
            size="sm"
            onClick={() => {
              router.push("/auth/account/payments");
              setShowModal(false);
            }}
          >
            <Trans id="auth.supplier.payout.settings">Settings</Trans>
          </ButtonLink>
        </div>
      </div>
    </Modal>
  );
};

export default PayoutMissingNotification;
